import {
  getTimeInAnchorTimeZone,
  guessTimeZone,
  updateToStartOfNextDayIfLastSlot,
  determineRBCEventEndWithEventStart,
  generateLargeRandomNumber,
  isAfterMinute,
  isBeforeMinute,
  IsEmptyObject,
  sortEventsJSDate,
  isSameOrAfterDay
} from "../services/commonUsefulFunctions";
import {
  parseISO,
  startOfMinute,
  isSameMinute,
  format,
  differenceInDays,
} from "date-fns";
import { SELECT_AVAILABILITY_COLOR } from "../services/globalVariables";
import { AVAILABILITY } from "../services/googleCalendarService";
import { ALL_DAY_EVENT_FORMAT } from "../components/groupVote/schedulingSharedVariables";
import {isEventSlotAllDayEvent} from "../lib/rbcFunctions";
import Fetcher from "../services/fetcher";

export const ACCEPTED = "accepted";
export const MAYBE = "maybe";

export function convertISOSlotsArrayToJSDate(slots, timeZone) {
  return slots.map((s) => {
    return convertISOSlotToJSDate(s, timeZone);
  });
}

export function convertISOSlotToJSDate(slot, timeZone) {
  // pass in string as iso string
  // need to return time in that time zone
  return {
    eventStart: getTimeInAnchorTimeZone(
      parseISO(slot.start),
      guessTimeZone(),
      timeZone
    ),
    eventEnd: getTimeInAnchorTimeZone(
      parseISO(slot.end),
      guessTimeZone(),
      timeZone
    ),
  };
}

// bug where we stored all day event start and end as the same time
// therefore need to have a legacy
export function createKeyFromSlot_legacy(slot) { 
  const { eventStart, eventEnd } = slot;
  if (isEventSlotAllDayEvent(slot) 
    && differenceInDays(startOfMinute(eventEnd), startOfMinute(eventStart)) === 1
  ) {
    // one day all day events
    return `${format(eventStart, ALL_DAY_EVENT_FORMAT)}_${format(eventStart, ALL_DAY_EVENT_FORMAT)}`;
  }
  
  return `${startOfMinute(eventStart).toISOString()}_${startOfMinute(
    eventEnd
  ).toISOString()}`;
}

export function createKeyFromSlot(slot) {
  const { eventStart, eventEnd } = slot;
  if (isSameMinute(eventStart, eventEnd) 
    || isMultiDaySlot(slot) 
    || isEventSlotAllDayEvent(slot)
  ) {
    return `${format(eventStart, ALL_DAY_EVENT_FORMAT)}_${format(eventEnd, ALL_DAY_EVENT_FORMAT)}`;
  }
  
  return `${startOfMinute(eventStart).toISOString()}_${startOfMinute(
    eventEnd
  ).toISOString()}`;
}

function createKeyFromSlotISOString(slot) {
  const { 
    start, 
    end, 
    startDate, 
    endDate
  } = slot;
  if (startDate) {
    return `${startDate}_${endDate}`;
  }
  return `${start}_${end}`;
}

export function createTemporaryEvent({
  startTime,
  endTime,
  index,
  hideCancel,
}) {
  let timeEnd = updateToStartOfNextDayIfLastSlot(endTime);
  let rbcEventEnd = determineRBCEventEndWithEventStart(startTime, timeEnd);

  return {
    isTemporary: true,
    isAvailability: true,
    eventStart: startTime,
    index,
    eventEnd: timeEnd,
    rbcEventEnd: rbcEventEnd,
    backgroundColor: SELECT_AVAILABILITY_COLOR,
    raw_json: { status: AVAILABILITY },
    id: generateLargeRandomNumber(),
    hideCancel,
    isGroupVote: true,
    displayAsAllDay: isEventSlotAllDayEvent({
      eventStart: startTime,
      eventEnd: endTime,
    }),
  };
}

export function determineSlotAttendeeIndex(bookingLink) {
  // get which attendee clicked on which slot
  const attendees = getAttendees(bookingLink);
  let slotAttendeeIndex = {};

  attendees.forEach((a) => {
    if (!a.slots || a.slots.length === 0) {
      return;
    }

    a.slots.forEach((s) => {
      const key = createKeyFromSlotISOString(s);
      if (!slotAttendeeIndex[key]) {
        slotAttendeeIndex[key] = [getAttendeeNameAndEmailKey(a)];
      } else {
        slotAttendeeIndex[key] = slotAttendeeIndex[key].concat(getAttendeeNameAndEmailKey(a));
      }
    });
  });

  return slotAttendeeIndex;
}

export function getAttendeeNameAndEmailKey(attendee) {
  return `${attendee?.name ?? ""}_${attendee?.email ?? ""}`;
}

export function getAttendees(bookingLink) {
  if (!bookingLink?.attendees) {
    return [];
  }

  const {
    attendees
  } = bookingLink;

  return attendees.filter(a => a?.slots?.length > 0);
}

export function getInitialBookingLinkDay(bookingLink) {
  if (
    !bookingLink?.selected_slots ||
    bookingLink.selected_slots?.length === 0
  ) {
    return new Date();
  }

  const { selected_slots, time_zone } = bookingLink;
  const jsDateArray = convertISOSlotsArrayToJSDate(selected_slots, time_zone);
  const filteredArray = filterEventsInThePast(jsDateArray);
  let initialStartDate;
  filteredArray.forEach((s) => {
    if (!initialStartDate) {
      initialStartDate = s.eventStart;
    } else if (isBeforeMinute(s.eventStart, initialStartDate)) {
      initialStartDate = s.eventStart;
    }
  });

  return initialStartDate ?? new Date();
}

export function filterEventsInThePast(eventList) {
  if (!eventList || eventList.length === 0) {
    return [];
  }

  const NOW = new Date();
  return eventList.filter(e => (isEventSlotAllDayEvent(e) && isSameOrAfterDay(e.eventEnd, NOW)) || isAfterMinute(e.eventEnd, NOW));
}

export function getSlotWithMostNumberOfVotes(attendeesIndex, selectedSlots) {
  let mostPopularKey;
  const NOW = new Date();

  if (selectedSlots.length === 0) {
    // if no selected slots
    return { eventStart: null, eventEnd: null };
  } else if (IsEmptyObject(attendeesIndex)) {
    // no attendee has picked any slots -> choose first one that's
    return selectedSlots.find(
      (s) =>
        s.isAvailability &&
        isAfterMinute(s.eventStart, NOW) &&
        isAfterMinute(s.eventEnd, NOW)
    );
  }

  Object.keys(attendeesIndex).forEach((k) => {
    if (!mostPopularKey) {
      mostPopularKey = k;
    } else if (
      attendeesIndex[k]?.length > attendeesIndex[mostPopularKey]?.length
    ) {
      mostPopularKey = k;
    }
  });

  if (!mostPopularKey) {
    return { eventStart: null, eventEnd: null };
  }

  return getEventStartAndEndFromKey(mostPopularKey);
}

export function getEventStartAndEndFromKey(key) {
  if (!key) {
    return { eventStart: null, eventEnd: null };
  }

  let startEndArray = key.split("_");
  if (startEndArray.length <= 1) {
    return { eventStart: null, eventEnd: null };
  }

  return {
    eventStart: parseISO(startEndArray[0]),
    eventEnd: parseISO(startEndArray[1]),
  };
}

export function isSameSlot(slotA, slotB) {
  return (
    slotA &&
    slotB &&
    isSameMinute(slotA.eventStart, slotB.eventStart) &&
    isSameMinute(slotA.eventEnd, slotB.eventEnd)
  );
}

export function isSlotInSelectSlots(slot, selectedSlots) {
  if (!slot.isAvailability) {
    return false;
  }

  return selectedSlots.some((s) => isSameSlot(slot, s));
}

export function parseEventsWithDefaultTimeZone(groupVoteLink) {
  if (IsEmptyObject(groupVoteLink)) {
    return [];
  }
  const { selected_slots } = groupVoteLink;

  return selected_slots.map((s) => {
    return {
      eventStart: parseISO(s.startDate || s.start),
      eventEnd: parseISO(s.endDate || s.end),
    };
  });
}

export function sortSlotsChronologically(slots) {
  if (!slots || slots.length === 0) {
    return [];
  }

  return slots.sort((a, b) => sortEventsJSDate(a, b));
}

export function convertSlotsIntoISOString(slots, timeZone) {
  if (!slots || slots?.length === 0) {
    return [];
  }

  const convertedSlots = slots.map((s) => {
    const isAllDay = isEventSlotAllDayEvent(s);
    return {
      eventStart: isAllDay
        ? s.eventStart
        : getTimeInAnchorTimeZone(s.eventStart, timeZone),
      eventEnd: isAllDay
        ? s.eventEnd
        : getTimeInAnchorTimeZone(s.eventEnd, timeZone),
    };
  });

  return convertedSlots.map((s) => {
    if (isEventSlotAllDayEvent(s)) {
      return {
        startDate: format(s.eventStart, ALL_DAY_EVENT_FORMAT),
        endDate: format(s.eventEnd, ALL_DAY_EVENT_FORMAT),
      };
    }
    return { start: s.eventStart.toISOString(), end: s.eventEnd.toISOString() };
  });
}

export function getNonExpiredSelectedSlotsWithDefaultTimeZone(groupVoteLink) {
  const jsDateArray = parseEventsWithDefaultTimeZone(groupVoteLink);
  return filterEventsInThePast(jsDateArray);
}

export async function loadFromUsernameAndSlug(url, propertyName) {
  const response = await Fetcher.get(url);
  if (!response || !!response.error) {
    return { response, token: null };
  }

  const property = response[propertyName];
  if (!property || !property.token) {
    throw `${propertyName} or token not found`; // this should never happen
  }

  return { response, token: property.token };
}

export function isMultiDaySlot(slot) {
  return differenceInDays(slot.eventEnd, slot.eventStart) > 1;
}
