import React, { Component }                         from 'react';


class GeneralErrorMessage extends Component {
  render() {
    const { children, message, title, titleStyle } = this.props;

    return (
      <div className="availability-error-container">
        <div style={titleStyle || {fontSize: 24, marginBottom: 5}}>
            {title || 'Oh no!'}
        </div>

        <div style={{fontSize: 14, fontWeight: 300, marginBottom: 70}}>
          {children || message || 'An error has occurred!'}
        </div>
      </div>
    );
  }
}

export default GeneralErrorMessage;
