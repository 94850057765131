import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { constructRequestURL } from "../services/api";
import {
  constructQueryParams,
  expandedDateAndTimeString,
  formatEvent,
  getGoogleEventId,
  handleError,
  isEventStartBeforeNow,
  sentryLogging,
  createError,
  isV2,
  getSlotsPath,
  getVimcalURLWithAttribution,
  trackEvent,
} from "../services/commonUsefulFunctions";
import Fetcher from "../services/fetcher";
import OneLinerMessager from "../components/oneLinerMessage";
import GeneralErrorMessage from "../components/generalErrorMessage";
import VimcalLogoWithMessage from "../components/vimcalLogoWithMessage";
import LoadingScreen from "../components/loadingScreen";
import {
  MOBILE_WIDTH_LIMIT,
  MOBILE_CONTAINER_STYLE,
  BLUE_BUTTON,
  WHITE_BUTTON,
  STATUS_CANCELLED,
} from "../services/globalVariables";
import TimeAndTimeZoneText from "../components/timeAndTimeZoneText";
import CustomButton from "../components/customButton";
import {loadFromUsernameAndSlug} from "../lib/availabilityFunctions";
import classNames from "classnames";
import HoverableLogo from "../components/hoverableLogo";
import Spinner from "../components/spinner";

class CancelPersonalLink extends Component {
  constructor(props) {
    super(props);
    this._isSubmitting = false;

    this.state = {
      token: props.match.params.token,
      error: false,
      isSubmitting: false,
      hasEventBeenCancelled: false,
      hasEventPassed: false,
      isLoading: true,
      isMobile: window.innerWidth <= MOBILE_WIDTH_LIMIT,
      displayTime: null,
      refreshCount: 0,
    };

    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.confirmCancellation = this.confirmCancellation.bind(this);
    this.cancelPersonalLink = this.cancelPersonalLink.bind(this);

    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentDidMount() {    
    this._isMounted = true;
    this.loadPersonalLink();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  render() {
    const {
      isMobile
    } = this.state;
    return (
      <div className={classNames(
        "availability-link-background", 
        isMobile ? "mobile-wrapper absolute top-0 bottom-0 left-0 right-0" : "web-wrapper h-screen overflow-y-auto flex-col"
      )}>
        <div
          className="availability-link-container"
          style={
            this.state.isMobile
              ? MOBILE_CONTAINER_STYLE
              : this.determineContainerStyle()
          }
        >
          {this.determineContent()}
        </div>
      </div>
    );
  }

  determineContainerStyle() {
    if (
      this.state.error ||
      this.state.hasEventBeenCancelled ||
      this.state.hasEventPassed ||
      this.state.isLoading
    ) {
      return { width: 500, height: 700 };
    } else {
      return {};
    }
  }

  determineContent() {
    if (this.state.error) {
      return <GeneralErrorMessage message={"An error has occured!"} />;
    } else if (this.state.expired) {
      return (
        <GeneralErrorMessage message={"The link you clicked on has expired!"} />
      );
    } else if (this.state.hasEventBeenCancelled) {
      return (
        <OneLinerMessager
          message={"The event has been successfully cancelled."}
        />
      );
    } else if (this.state.hasEventPassed) {
      return (
        <OneLinerMessager message={"Sorry, this event has already passed."} />
      );
    } else if (this.state.displayConfirmCancellation) {
      return this.renderConfirmCancellation();
    } else {
      return <LoadingScreen />;
    }
  }

  renderConfirmCancellation() {
    const {
      isMobile,
      isSubmitting
    } = this.state;

    const BUTTON_WIDTH_HEIGHT = { width: 165, height: 50 };

    const determineLabel = () => {
      if (isSubmitting) {
        return <Spinner useSmallSpinner={true} className="absolute -top-4" />
      }

      return "Yes, cancel event"
    }

    return (
      <div className={classNames("confirm-cancellation-container", isMobile ? "justify-between" : "")}>
        <div style={{height: "calc(100% - 50px)"}}>
          <div
            className="text-center"
            style={{
              fontWeight: 400,
              fontSize: 20,
              width: "100%",
              justifyContent: "center",
              display: "flex",
              paddingTop: isMobile ? 30 : 75,
            }}
          >
            Confirm Cancellation
          </div>

          <div className="margin-top-twenty display-flex flex-direction-column align-items-center">
            <TimeAndTimeZoneText time={this.state.displayTime} />
          </div>

          <div className="text-center" style={{ marginTop: 50, fontSize: 14 }}>
            Please confirm that you would like to cancel this event. A
            cancellation email will also go out to the invitee.
          </div>

          <div
            className="flex items-center justify-center"
            style={{ marginTop: 50 }}
          >
            <CustomButton
              buttonType={WHITE_BUTTON}
              onClick={this.onClickDoNotCancel}
              addPaddingToRight={true}
              label="No, don't cancel"
              style={BUTTON_WIDTH_HEIGHT}
            />

            <CustomButton
              buttonType={BLUE_BUTTON}
              onClick={this.confirmCancellation}
              label={determineLabel()}
              style={{...BUTTON_WIDTH_HEIGHT, ...{ position: "relative" }}}
            />
          </div>
        </div>

        {isMobile ? <HoverableLogo /> : null}
      </div>
    );
  }

  renderOneLinerMessage(message) {
    return (
      <div className="availability-error-container">
        <div style={{ fontSize: 18, fontWeight: 300, marginBottom: 70 }}>
          {message}
        </div>

        <VimcalLogoWithMessage />
      </div>
    );
  }

  async loadPersonalLink() {
    const { refreshCount } = this.state;
    const { hasSlug, match } = this.props;
    const { username, slug } = match.params;

    if (!hasSlug) {
      return this.cancelPersonalLink();
    }

    const path = `${getSlotsPath()}/by-slug/${username}/${slug}`;
    const url = constructRequestURL(path, isV2());
    let sentryContext = { path, url };

    try {
      const { response, token } = await loadFromUsernameAndSlug(url, 'availabilities');
      sentryContext.response = response;

      if (this.doesResponseHaveErrorAndHandleError(response, this.shouldRefresh())) {
        return;
      }

      this.setState({ token }, this.cancelPersonalLink);
    } catch(error) {
      sentryContext = {
        ...sentryContext,
        username,
        slug,
        type: "reschedule",
        action: "submit reschedule",
      }

      const shouldRefresh = this.shouldRefresh();
      this.handleCatchError(error, sentryContext, shouldRefresh);

      if (this._isMounted && shouldRefresh) {
        this.setState({ refreshCount: refreshCount + 1 }, this.loadPersonalLink);
      }
    }
  }

  cancelPersonalLink() {
    const path = `${getSlotsPath()}/appointment/${this.state.token}`;

    const url = constructRequestURL(path, isV2());

    const sentryContext = {
      token: this.state.token,
      url,
      path,
      type: "reschedule",
      action: "submit reschedule",
      event: this.state.event,
    };

    return Fetcher.get(url)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        sentryContext.response = response;
        if (
          this.doesResponseHaveErrorAndHandleError(
            response,
            this.shouldRefresh()
          )
        ) {
          return;
        }

        const { event, calendar_provider_id, google_calendar_id } = response;

        if (
          !event ||
          !(
            event &&
            event.raw_json
            // event.raw_json.creator &&
            // event.raw_json.creator.email
          )
        ) {
          let token = this.state.token || "no_token";
          sentryLogging({
            token,
            error: "error with event",
            path,
            type: "cancel",
            event,
          });

          this.setState({ error: true });
          return;
        }

        if (event.raw_json && event.raw_json.status === STATUS_CANCELLED) {
          this.setState({ hasEventBeenCancelled: true });
          return;
        }

        if (this.checkForPastEventAndHandlePastEvent(response)) {
          return;
        }

        let formattedEvent = formatEvent(event.raw_json);

        let displayTime = expandedDateAndTimeString(formattedEvent);
        this.setState({
          event,
          displayConfirmCancellation: true,
          displayTime,
          calendar_provider_id,
          google_calendar_id
        });
      })
      .catch((error) => {
        let shouldRefresh = this.shouldRefresh();
        this.handleCatchError(error, sentryContext, shouldRefresh);

        if ((this._isMounted && this.shouldRefresh(), shouldRefresh)) {
          this.setState(
            { refreshCount: this.state.refreshCount + 1 },
            this.cancelPersonalLink
          );
        }
      });
  }

  onClickDoNotCancel() {
    window.location.href = getVimcalURLWithAttribution();
  }

  confirmCancellation() {
    if (this._isSubmitting) {
      return;
    }
    this._isSubmitting = true;
    this.setState({isSubmitting: true});

    const event = this.state.event;

    const userEventId = event.user_event_id;

    const path = `${getSlotsPath()}/${userEventId}/cancel`;
    const params = isV2() ? {
      sendUpdates: "all",
      calendar_provider_id: this.state.calendar_provider_id,
      google_event_id: getGoogleEventId(event),
    } : {
      sendUpdates: "all",
      google_calendar_id: this.state.google_calendar_id,
      google_event_id: getGoogleEventId(event),
    };
    const queryParams = constructQueryParams(params);
    const url = `${constructRequestURL(path, isV2())}?${queryParams}`;

    let payloadData = {
      body: JSON.stringify({
        user_event_id: userEventId,
        appointment_token: this.state.token,
      }),
    };

    let sentryContext = {
      token: this.state.token,
      url,
      path,
      type: "reschedule",
      action: "submit reschedule",
      payloadData,
      event: this.state.event,
    };

    this.trackCancel({label: "pressed cancel"});

    return Fetcher.delete(url, payloadData)
      .then((response) => {
        if (!this._isMounted) {
          return;
        }

        sentryContext.response = response;
        if (this.doesResponseHaveErrorAndHandleError(response)) {
          this.trackCancel({label: "error cancelling"});
          return;
        }

        this.setState({ hasEventBeenCancelled: true, isLoading: false });
        this.trackCancel({label: "confirmed cancellation"});
      })
      .catch((error) => {
        this.handleCatchError(error, sentryContext);
      });
  }

  trackCancel({label}) {
    trackEvent({
      category: "availability - cancel",
      action: `token: ${this.state.token} || calendar_provider_id: ${this.state.calendar_provider_id}`,
      label
    });
  }

  handleCatchError(
    error,
    sentryContext,
    doNotShowError = false,
    shouldUpdateSubmitting = false
  ) {
    handleError(error, sentryContext);

    if (!this._isMounted || doNotShowError) {
      return;
    }

    if (shouldUpdateSubmitting) {
      this._isSubmitting = false;
      this.setState({ error: true, isSubmitting: false });
    } else {
      this.setState({ error: true });
    }
  }

  checkForPastEventAndHandlePastEvent(response) {
    if (isEventStartBeforeNow(response)) {
      this.setState({ hasEventPassed: true });
      return true;
    }

    return false;
  }

  doesResponseHaveErrorAndHandleError(response, doNotSetError = false) {
    let hasError = false;

    if (!response) {
      createError("no response");

      hasError = true;
      !doNotSetError && this.setState({ error: true });
    } else if (response.error) {
      hasError = true;
      if (response.error === "expired") {
        this.setState({ expired: true });
      } else {
        createError("error in response");

        !doNotSetError && this.setState({ error: true });
      }
    } else if (response.type === "error") {
      createError("response type error");

      hasError = true;

      !doNotSetError && this.setState({ error: true });
    }

    return hasError;
  }

  handleWindowSizeChange() {
    if (!this._isMounted) {
      return;
    }

    let displayWidth = window.innerWidth;

    if (displayWidth < 700 && !this.state.isMobile) {
      this.setState({ isMobile: true });
    } else if (displayWidth > 700 && this.state.isMobile) {
      this.setState({ isMobile: false });
    }
  }

  shouldRefresh() {
    return this.state.refreshCount < 3;
  }
}

export default withRouter(CancelPersonalLink);
