import React, { Component }                         from 'react';
import EventInfoWithIcons                           from './eventInfoWithIcons';
import {
  determineTitleWithDuration,
  CapitalizeFirstLetterOfEveryWord,
  IsEmptyObject,
  doesTitleIncludeVariableBlocks,
  replaceVariableBlocksWithValue
}                                                   from '../services/commonUsefulFunctions';
import classNames from 'classnames';
import HostDetails from './hostDetails';


class AvailabilityLinkEventInfo extends Component {
  render() {
    const {
      additionalClassName,
      alignItems
    } = this.props;

    const {
      isMobile
    } = this.props.info;

    return (
      <div
        className="availability-event-info"
        style={isMobile
          ? {width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: alignItems ?? "flex-start", padding: 15}
          : {paddingTop: 25, paddingBottom: 25, paddingLeft: 30}
        }
      >
        <HostDetails
          hostDisplayName={this.determineName()}
          profilePhotoSrc={this.props.info.profile_photo_url}
          socialLinks={this.props.info.social_links}
        />

        {(this.props.info.duration || this.props.info.isGroupVote) &&
          <div
            className={classNames("availability-link-title")}
            style={{fontSize: 20}}
          >
            {this.determineLargeTitle()}
          </div>
        }

        <EventInfoWithIcons
          info={this.props.info}
          additionalClassName={classNames(additionalClassName)}
          containerClassName={isMobile ? "mb-5 mt-5" : "mt-5 mb-2"}
          addMarginRightToEachSection={true}
        />
      </div>
    );
  }

  determineName() {
    if (IsEmptyObject(this.props.info.user)) {
      return null;
    }

    if (this.props.info.invitee_full_name) {
      return `${CapitalizeFirstLetterOfEveryWord(this.props.info.invitee_full_name)} and ${this.getSelfName()}`;
    }

    return this.getSelfName();
  }

  getSelfName() {
    if (this.isSchedulingForExec()) {
      return CapitalizeFirstLetterOfEveryWord(this.props.info.user?.full_name)
        || this.props.info.user.email || "";
    }

    return CapitalizeFirstLetterOfEveryWord(this.props.info.sender_full_name)
      || CapitalizeFirstLetterOfEveryWord(this.props.info.master_account?.full_name)
      || CapitalizeFirstLetterOfEveryWord(this.props.info.user?.full_name)
      || this.props.info.user.email;
  }

  isSchedulingForExec() {
    return this.props.info.is_scheduling_for_exec;
  }

  determineLargeTitle() {
    const {
      personal_link,
      title,
      invitee_full_name: inviteeName,
      inputName,
      duration,
      custom_questions
    } = this.props.info;
    const {
      companyName
    } = this.props;

    if (personal_link?.name) {
      return personal_link.name;
    }

    // if we wanted to show title
    // const updatedTitle = replaceVariableBlocksWithValue({
    //   title,
    //   inviteeName: inviteeName || inputName,
    //   companyName,
    //   questions: custom_questions
    // });

    if (title && !doesTitleIncludeVariableBlocks({title})) {
      return title;
    }

    return determineTitleWithDuration(duration);
  }
}

export default AvailabilityLinkEventInfo;
