import {
  isTypeOfNumber,
  isValidTimeZone,
  removeDuplicatesFromArray,
} from "./commonUsefulFunctions";

// time zone abbreviations: https://greenwichmeantime.com/time-zone/abbreviations/
export const FLOATING_POINT_GMT_TIMEZONES = {
  "-3.5": "Asia/Tehran",
  "-4.5": "Asia/Kabul",
  "-5.5": "Asia/Kolkata",
  "-5.75": "Asia/Kathmandu",
  "-6.5": "Asia/Rangoon",
  "-8.75": "Australia/Eucla",
  "-9.5": "Australia/Darwin",
  "-10.5": "Australia/Lord_Howe",
  "-11.5": "Pacific/Norfolk",
  "-12.75": "Pacific/Chatham",
  3.5: "America/St_Johns",
  2.5: "America/Miquelon",
  4.5: "America/Caracas",
};

const TIME_ZONE_GMT_SEARCH_KEY = {
  "gmt+12": "Etc/GMT-12",
  "gmt+11": "Etc/GMT-11",
  "gmt+10": "Etc/GMT-10",
  "gmt+9": "Etc/GMT-9",
  "gmt+8": "Etc/GMT-8",
  "gmt+7": "Etc/GMT-7",
  "gmt+6": "Etc/GMT-6",
  "gmt+5": "Etc/GMT-5",
  "gmt+4": "Etc/GMT-4",
  "gmt+3": "Etc/GMT-3",
  "gmt+2": "Etc/GMT-2",
  "gmt+1": "Etc/GMT-1",
  GMT: "Etc/GMT",
  "gmt-1": "Etc/GMT+1",
  "gmt-2": "Etc/GMT+2",
  "gmt-3": "Etc/GMT+3",
  "gmt-4": "Etc/GMT+4",
  "gmt-5": "Etc/GMT+5",
  "gmt-6": "Etc/GMT+6",
  "gmt-7": "Etc/GMT+7",
  "gmt-8": "Etc/GMT+8",
  "gmt-9": "Etc/GMT+9",
  "gmt-10": "Etc/GMT+10",
  "gmt-11": "Etc/GMT+11",
  "gmt-12": "Etc/GMT+12",
};

export const ALL_VALID_GMT_OFFSET_TIMEZONES = Object.values(
  TIME_ZONE_GMT_SEARCH_KEY
);

export const POPULAR_TIME_ZONES_INDEX = {
  LOS_ANGELES: "America/Los_Angeles",
  NEW_YORK: "America/New_York",
  CHICAGO: "America/Chicago",
  LONDON: "Europe/London",
  PARIS: "Europe/Paris",
  BERLIN: "Europe/Berlin",
  ISTANBUL: "Europe/Istanbul",
  MOSCOW: "Europe/Moscow",
  DUBAI: "Asia/Dubai",
  HONG_KONG: "Asia/Hong_Kong",
  DENVER: "America/Denver",
  SYDNEY: "Australia/Sydney",
  TOKYO: "Asia/Tokyo",
  SINGAPORE: "Asia/Singapore",
  SHANGHAI: "Asia/Shanghai",
  MUMBAI: "Asia/Kolkata",
  UNIVERSAL: "Universal",
};

export function stringContainsGMTAndNumber(str) {
  if (!str) {
    return false;
  }

  const pattern = /gmt\s*[+-]\s*\d+/i;
  return pattern.test(str);
}

export function getOffSetFromGMTSearchString(str) {
  // str could be "GMT+1;
  if (!str) {
    return null;
  }

  const pattern = /gmt\s*[+-]\s*(\d+)/i;
  const matches = str.match(pattern);
  return matches[1];
}

export function getAllTimeZoneForGMTOffset(gmtOffsetString) {
  // e.g. gmtOffsetString = GMT-5:00
  const matchingString = TIME_ZONE_GMT_SEARCH_KEY[gmtOffsetString];
  if (!matchingString) {
    return [];
  }

  return [matchingString];
}

function getZoneNameIndexFromGMTOffset() {
  const index = {}; // timeZone: {abbreviations};
  Object.keys(TIME_ZONE_GMT_SEARCH_KEY).forEach((gmtKey) => {
    index[TIME_ZONE_GMT_SEARCH_KEY[gmtKey]] = getGMTSearchString(gmtKey);
  });

  return index;
}

function getGMTSearchString(gmtString) {
  if (gmtString.includes("+")) {
    const splitValue = gmtString.split("+");
    return `gmt+${splitValue[1]}, gmt + ${splitValue[1]}, gmt+ ${splitValue[1]}, gmt +${splitValue[1]}`;
  } else if (gmtString.includes("-")) {
    // minus value
    const splitValue = gmtString.split("-");
    return `gmt-${splitValue[1]}, gmt - ${splitValue[1]}, gmt- ${splitValue[1]}}, gmt -${splitValue[1]}`;
  }
  return "gmt, gmt 0, gmt0, gmt-0, gmt+0, gmt - 0, gmt -0, gmt + 0, gmt +0, gmt- 0, gmt+ 0";
}

export const TIME_ZONE_ABBREVIATION_ARRAY = [
  "pt",
  "ct",
  "mt",
  "et",
  "pacific",
  "central",
  "mountain",
  "eastern",
  "pst",
  "cst",
  "mst",
  "est",
  "pdt",
  "edt",
  "cdt",
  "bst",
  "cest",
  "hkt",
  "cst",
  "sgt",
  "jst",
  "utc",
  "gmt",
  "gmt",
  "eat",
  "cet",
  "eat",
  "eat",
  "gmt",
  "wat",
  "gmt",
  "gmt",
  "cat",
  "wat",
  "cat",
  "eet",
  "+01",
  "cest",
  "gmt",
  "gmt",
  "eat",
  "eat",
  "wat",
  "+01",
  "gmt",
  "cat",
  "cat",
  "sast",
  "eat",
  "eat",
  "cat",
  "cat",
  "wat",
  "wat",
  "wat",
  "gmt",
  "wat",
  "cat",
  "cat",
  "wat",
  "cat",
  "sast",
  "sast",
  "eat",
  "gmt",
  "eat",
  "wat",
  "wat",
  "gmt",
  "gmt",
  "wat",
  "gmt",
  "gmt",
  "eet",
  "cet",
  "cat",
  "hdt",
  "akdt",
  "ast",
  "ast",
  "-03",
  "-03",
  "-03",
  "-03",
  "-03",
  "-03",
  "-03",
  "-03",
  "-03",
  "-03",
  "-03",
  "-03",
  "-03",
  "-03",
  "ast",
  "-04",
  "est",
  "hdt",
  "-03",
  "cdt",
  "ast",
  "-03",
  "cst",
  "ast",
  "-04",
  "cot",
  "mdt",
  "art",
  "mdt",
  "-04",
  "est",
  "-04",
  "-03",
  "-03",
  "est",
  "mdt",
  "est",
  "-03",
  "cst",
  "mst",
  "-04",
  "ast",
  "gmt",
  "mst",
  "mst",
  "mdt",
  "edt",
  "ast",
  "mdt",
  "-05",
  "cst",
  "pdt",
  "mst",
  "edt",
  "-03",
  "adt",
  "-02",
  "adt",
  "edt",
  "ast",
  "ast",
  "cst",
  "-05",
  "-04",
  "adt",
  "cdt",
  "mst",
  "edt",
  "cdt",
  "edt",
  "edt",
  "cdt",
  "edt",
  "edt",
  "edt",
  "edt",
  "mdt",
  "edt",
  "est",
  "-03",
  "akdt",
  "edt",
  "edt",
  "cdt",
  "ast",
  "-04",
  "pet",
  "edt",
  "ast",
  "-03",
  "cst",
  "-04",
  "ast",
  "ast",
  "cdt",
  "mdt",
  "ast",
  "cdt",
  "cdt",
  "akdt",
  "cdt",
  "-02",
  "adt",
  "cdt",
  "-03",
  "edt",
  "ast",
  "edt",
  "edt",
  "akdt",
  "-02",
  "cdt",
  "cdt",
  "cdt",
  "-02",
  "mdt",
  "est",
  "edt",
  "-03",
  "mst",
  "edt",
  "ast",
  "-05",
  "amt",
  "ast",
  "-03",
  "cdt",
  "cdt",
  "brt",
  "cst",
  "cdt",
  "-05",
  "-03",
  "pdt",
  "-03",
  "clt",
  "ast",
  "brst",
  "+00",
  "mdt",
  "akdt",
  "ast",
  "ndt",
  "ast",
  "ast",
  "ast",
  "ast",
  "cst",
  "cst",
  "adt",
  "edt",
  "pdt",
  "edt",
  "ast",
  "pdt",
  "ast",
  "mst",
  "cdt",
  "akdt",
  "mdt",
  "+08",
  "+07",
  "+10",
  "+11",
  "+05",
  "nzst",
  "-03",
  "-03",
  "nzst",
  "+03",
  "+02",
  "+06",
  "cest",
  "+03",
  "+06",
  "eest",
  "+12",
  "+05",
  "+05",
  "+05",
  "+05",
  "+05",
  "ast",
  "ast",
  "+04",
  "ict",
  "+07",
  "eest",
  "+06",
  "+08",
  "ist",
  "+09",
  "+08",
  "cst",
  "cst",
  "+0530",
  "+06",
  "eest",
  "+06",
  "+09",
  "gst",
  "+05",
  "eest",
  "eest",
  "cst",
  "eest",
  "ict",
  "+07",
  "+08",
  "myt",
  "wib",
  "wit",
  "idt",
  "+0430",
  "+12",
  "pkt",
  "+06",
  "+0545",
  "+0545",
  "+09",
  "ist",
  "+07",
  "art",
  "+08",
  "ast",
  "cst",
  "cst",
  "+11",
  "wita",
  "pst",
  "+04",
  "eest",
  "+07",
  "+07",
  "+06",
  "+05",
  "+07",
  "wib",
  "kst",
  "ast",
  "+06",
  "+05",
  "+0630",
  "+03",
  "+07",
  "+11",
  "+05",
  "kst",
  "+11",
  "cst",
  "+05",
  "+04",
  "irdt",
  "idt",
  "+06",
  "+06",
  "+07",
  "wita",
  "+08",
  "+08",
  "+06",
  "+10",
  "+07",
  "+10",
  "+09",
  "+0630",
  "+05",
  "amt",
  "+00",
  "adt",
  "west",
  "-01",
  "west",
  "west",
  "cest",
  "west",
  "gmt",
  "-02",
  "gmt",
  "-03",
  "aest",
  "acst",
  "aest",
  "acst",
  "aest",
  "aest",
  "acst",
  "+0845",
  "aest",
  "+1030",
  "aest",
  "+1030",
  "aest",
  "aest",
  "acst",
  "awst",
  "aest",
  "acst",
  "aest",
  "aest",
  "aest",
  "awst",
  "acst",
  "-05",
  "-02",
  "-03",
  "-04",
  "cest",
  "cdt",
  "adt",
  "cdt",
  "edt",
  "mdt",
  "ndt",
  "pdt",
  "cst",
  "mst",
  "-04",
  "-06",
  "cdt",
  "eest",
  "est",
  "edt",
  "eet",
  "ist",
  "gmt",
  "gmt",
  "-01",
  "-10",
  "-11",
  "-12",
  "-02",
  "-03",
  "-04",
  "-05",
  "-06",
  "-07",
  "-08",
  "-09",
  "gmt",
  "+01",
  "+10",
  "+11",
  "+12",
  "+13",
  "+14",
  "+02",
  "+03",
  "+04",
  "+05",
  "+06",
  "+07",
  "+08",
  "+09",
  "gmt",
  "gmt",
  "utc",
  "utc",
  "utc",
  "utc",
  "cest",
  "cest",
  "+04",
  "eest",
  "bst",
  "cest",
  "cest",
  "cest",
  "cest",
  "eest",
  "cest",
  "cest",
  "eest",
  "cest",
  "ist",
  "cest",
  "bst",
  "eest",
  "bst",
  "trt",
  "bst",
  "eet",
  "eest",
  "+03",
  "west",
  "cest",
  "cest",
  "cest",
  "cest",
  "eest",
  "msk",
  "cest",
  "msk",
  "eest",
  "cest",
  "cest",
  "cest",
  "eest",
  "cest",
  "+04",
  "cest",
  "cest",
  "+04",
  "msk",
  "cest",
  "eest",
  "cest",
  "eest",
  "cest",
  "eest",
  "+04",
  "eest",
  "cest",
  "cest",
  "cest",
  "eest",
  "+04",
  "cest",
  "cest",
  "eest",
  "cest",
  "bst",
  "bst",
  "gmt",
  "gmt",
  "gmt",
  "gmt",
  "gmt",
  "hst",
  "hkt",
  "gmt",
  "eat",
  "+06",
  "+07",
  "+0630",
  "eat",
  "+05",
  "+04",
  "+05",
  "+04",
  "eat",
  "+04",
  "irdt",
  "idt",
  "est",
  "jst",
  "+12",
  "eet",
  "mest",
  "mst",
  "mdt",
  "pdt",
  "mdt",
  "cdt",
  "nzst",
  "+1245",
  "mdt",
  "cst",
  "pdt",
  "+13",
  "nzst",
  "+11",
  "+1245",
  "+10",
  "-06",
  "+11",
  "+13",
  "+13",
  "fjt",
  "+12",
  "-06",
  "-09",
  "+11",
  "chst",
  "hst",
  "hst",
  "+14",
  "+11",
  "+12",
  "+12",
  "-0930",
  "sst",
  "+12",
  "-11",
  "+11",
  "+11",
  "sst",
  "+09",
  "-08",
  "+11",
  "+11",
  "+10",
  "-10",
  "chst",
  "sst",
  "-10",
  "+12",
  "+13",
  "+10",
  "+12",
  "+12",
  "+10",
  "cest",
  "west",
  "cst",
  "kst",
  "sgt",
  "trt",
  "utc",
  "akdt",
  "hdt",
  "mst",
  "cdt",
  "edt",
  "edt",
  "hst",
  "cdt",
  "edt",
  "mdt",
  "pdt",
  "pdt",
  "sst",
  "utc",
  "msk",
  "west",
  "utc",
];

export const TIME_ZONE_ABBREVIATION_OVERRIDE_INDEX = {
  "Asia/Singapore": {
    dayLightSavings: "SGT",
    standard: "SGT",
  },
  "America/Buenos_Aires": {
    dayLightSavings: "ART",
    standard: "ART",
  },
  "America/Argentina/Buenos_Aires": {
    dayLightSavings: "ART",
    standard: "ART",
  },
  "America/Argentina/Catamarca": {
    dayLightSavings: "ART",
    standard: "ART",
  },
  "America/Argentina/Mendoza": {
    dayLightSavings: "ART",
    standard: "ART",
  },
  "America/Lima": {
    dayLightSavings: "PET",
    standard: "PET",
  },
  "America/Mendoza": {
    dayLightSavings: "AST",
    standard: "AST",
  },
  "America/Porto_Velho": {
    dayLightSavings: "AMT",
    standard: "AMT",
  },
  "America/Recife": {
    dayLightSavings: "BRT",
    standard: "BRT",
  },
  "America/Sao_Paulo": {
    dayLightSavings: "BRT",
    standard: "BRST",
  },
  "America/Santiago": {
    dayLightSavings: "CLST",
    standard: "CLT",
  },
  "Asia/Baghdad": {
    dayLightSavings: "AST",
    standard: "AST",
  },
  "Asia/Bahrain": {
    dayLightSavings: "AST",
    standard: "AST",
  },
  "Asia/Bangkok": {
    dayLightSavings: "ICT",
    standard: "ICT",
  },
  "Asia/Dubai": {
    dayLightSavings: "GST",
    standard: "GST",
  },
  "Asia/Ho_Chi_Minh": {
    dayLightSavings: "ICT",
    standard: "ICT",
  },
  "Asia/Istanbul": {
    dayLightSavings: "MYT",
    standard: "MYT",
  },
  "Asia/Kuala_Lumpur": {
    dayLightSavings: "MYT",
    standard: "MYT",
  },
  "Asia/Kuwait": {
    dayLightSavings: "AST",
    standard: "AST",
  },
  "Asia/Qatar": {
    dayLightSavings: "AST",
    standard: "AST",
  },
  "Asia/Tehran": {
    dayLightSavings: "IRDT",
    standard: "IRST",
  },
  "Asia/Yerevan": {
    dayLightSavings: "AMT",
    standard: "AMT",
  },
  "Europe/Istanbul": {
    dayLightSavings: "TRT",
    standard: "TRT",
  },
  "Europe/Minsk": {
    dayLightSavings: "MSK",
    standard: "MSK",
  },
  Iran: {
    dayLightSavings: "IRDT",
    standard: "IRST",
  },
  "Pacific/Fiji": {
    dayLightSavings: "FJT",
    standard: "FJT",
  },
  Singapore: {
    dayLightSavings: "SGT",
    standard: "SGT",
  },
  Turkey: {
    dayLightSavings: "TRT",
    standard: "TRT",
  },
  "America/Bogota": {
    dayLightSavings: "COT",
    standard: "COT",
  },
  "Asia/Calcutta": {
    dayLightSavings: "IST",
    standard: "IST",
  },
  "Europe/London": {
    dayLightSavings: "BST",
    standard: "GMT",
  },
  "Europe/Paris": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Asia/Hong_Kong": {
    dayLightSavings: "HKT",
    standard: "HKT",
  },
  "Asia/Shanghai": {
    dayLightSavings: "CST",
    standard: "CST",
  },
  "Asia/Tokyo": {
    dayLightSavings: "JST",
    standard: "JST",
  },
  "Africa/Nairobi": {
    dayLightSavings: "EAT",
    standard: "EAT",
  },
  "Africa/Algiers": {
    dayLightSavings: "CET",
    standard: "CET",
  },
  "Africa/Lagos": {
    dayLightSavings: "WAT",
    standard: "WAT",
  },
  "Africa/Maputo": {
    dayLightSavings: "CAT",
    standard: "CAT",
  },
  "Africa/Cairo": {
    dayLightSavings: "EET",
    standard: "EET",
  },
  "Africa/Ceuta": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Africa/Johannesburg": {
    dayLightSavings: "SAST",
    standard: "SAST",
  },
  "Africa/Juba": {
    dayLightSavings: "EAT",
    standard: "EAT",
  },
  "Africa/Khartoum": {
    dayLightSavings: "CAT",
    standard: "CAT",
  },
  "Africa/Ndjamena": {
    dayLightSavings: "WAT",
    standard: "WAT",
  },
  "Africa/Tripoli": {
    dayLightSavings: "EET",
    standard: "EET",
  },
  "Africa/Tunis": {
    dayLightSavings: "CET",
    standard: "CET",
  },
  "Africa/Windhoek": {
    dayLightSavings: "CAT",
    standard: "CAT",
  },
  "America/Chihuahua": {
    dayLightSavings: "MST",
    standard: "MDT",
  },
  "America/Havana": {
    dayLightSavings: "CST",
    standard: "CDT",
  },
  "America/Hermosillo": {
    dayLightSavings: "MST",
    standard: "MST",
  },
  "America/Mazatlan": {
    dayLightSavings: "MST",
    standard: "MDT",
  },
  "America/St_Johns": {
    dayLightSavings: "NST",
    standard: "NDT",
  },
  "Pacific/Auckland": {
    dayLightSavings: "NZDT",
    standard: "NZST",
  },
  "Europe/Oslo": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Asia/Amman": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Asia/Beirut": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Asia/Kolkata": {
    dayLightSavings: "IST",
    standard: "IST",
  },
  "Asia/Damascus": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Asia/Famagusta": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Asia/Gaza": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Asia/Hebron": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Asia/Jakarta": {
    dayLightSavings: "WIB",
    standard: "WIB",
  },
  "Asia/Jayapura": {
    dayLightSavings: "WIT",
    standard: "WIT",
  },
  "Asia/Jerusalem": {
    dayLightSavings: "IST",
    standard: "IDT",
  },
  "Asia/Karachi": {
    dayLightSavings: "PKT",
    standard: "PKT",
  },
  "Asia/Macau": {
    dayLightSavings: "CST",
    standard: "CST",
  },
  "Asia/Makassar": {
    dayLightSavings: "WITA",
    standard: "WITA",
  },
  "Asia/Manila": {
    dayLightSavings: "PHT",
    standard: "PHT",
  },
  "Asia/Nicosia": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Asia/Pontianak": {
    dayLightSavings: "WIB",
    standard: "WIB",
  },
  "Asia/Pyongyang": {
    dayLightSavings: "KST",
    standard: "KST",
  },
  "Asia/Seoul": {
    dayLightSavings: "KST",
    standard: "KST",
  },
  "Asia/Taipei": {
    dayLightSavings: "CST",
    standard: "CST",
  },
  "Atlantic/Canary": {
    dayLightSavings: "WEST",
    standard: "WET",
  },
  "Atlantic/Faroe": {
    dayLightSavings: "WEST",
    standard: "WET",
  },
  "Atlantic/Madeira": {
    dayLightSavings: "WEST",
    standard: "WET",
  },
  "Australia/Sydney": {
    dayLightSavings: "AEDT",
    standard: "AEST",
  },
  "Australia/Adelaide": {
    dayLightSavings: "ACDT",
    standard: "ACST",
  },
  "Australia/Brisbane": {
    dayLightSavings: "AEST",
    standard: "AEST",
  },
  "Australia/Broken_Hill": {
    dayLightSavings: "ACDT",
    standard: "ACST",
  },
  "Australia/Currie": {
    dayLightSavings: "AEDT",
    standard: "AEST",
  },
  "Australia/Darwin": {
    dayLightSavings: "ACST",
    standard: "ACST",
  },
  "Australia/Hobart": {
    dayLightSavings: "AEDT",
    standard: "AEST",
  },
  "Australia/Lindeman": {
    dayLightSavings: "AEST",
    standard: "AEST",
  },
  "Australia/Melbourne": {
    dayLightSavings: "AEDT",
    standard: "AEST",
  },
  "Australia/Perth": {
    dayLightSavings: "AWST",
    standard: "AWST",
  },
  CET: {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  EET: {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Dublin": {
    dayLightSavings: "GMT",
    standard: "IST",
  },
  EST: {
    dayLightSavings: "EST",
    standard: "EST",
  },
  "Europe/Amsterdam": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Andorra": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Athens": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Belgrade": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Berlin": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Prague": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Brussels": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Bucharest": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Budapest": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Zurich": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Chisinau": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Copenhagen": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Gibraltar": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Helsinki": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Kaliningrad": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Kiev": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Lisbon": {
    dayLightSavings: "WEST",
    standard: "WET",
  },
  "Europe/Luxembourg": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Madrid": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Malta": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Monaco": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Moscow": {
    dayLightSavings: "MSK",
    standard: "MSK",
  },
  "Europe/Riga": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Rome": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Simferopol": {
    dayLightSavings: "MSK",
    standard: "MSK",
  },
  "Europe/Sofia": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Stockholm": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Tallinn": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Tirane": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Uzhgorod": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Vienna": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Vilnius": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  "Europe/Warsaw": {
    dayLightSavings: "CEST",
    standard: "CET",
  },
  "Europe/Zaporozhye": {
    dayLightSavings: "EEST",
    standard: "EET",
  },
  HST: {
    dayLightSavings: "HST",
    standard: "HST",
  },
  MET: {
    dayLightSavings: "MEST",
    standard: "MET",
  },
  MST: {
    dayLightSavings: "MST",
    standard: "MST",
  },
  "Pacific/Guam": {
    dayLightSavings: "ChST",
    standard: "ChST",
  },
  "Pacific/Pago_Pago": {
    dayLightSavings: "SST",
    standard: "SST",
  },
  WET: {
    dayLightSavings: "WEST",
    standard: "WET",
  },
};

export const POPULAR_TIME_ZONES = [
  "America/Los_Angeles",
  "America/New_York",
  "America/Chicago",
  "America/Denver",
  "Europe/London",
  "Europe/Paris",
  "Asia/Hong_Kong",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Tokyo",
  "Asia/Kolkata",
  "Universal",
];

// https://stackoverflow.com/questions/38399465/how-to-get-list-of-all-timezones-in-javascript
export const IANNA_TIME_ZONES = removeDuplicatesFromArray(
  [
    "Europe/Andorra",
    "Asia/Dubai",
    "Asia/Kabul",
    "Europe/Tirane",
    "Asia/Yerevan",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    "Antarctica/Mawson",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Cordoba",
    "America/Argentina/Salta",
    "America/Argentina/Jujuy",
    "America/Argentina/Tucuman",
    "America/Argentina/Catamarca",
    "America/Argentina/La_Rioja",
    "America/Argentina/San_Juan",
    "America/Argentina/Mendoza",
    "America/Argentina/San_Luis",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Ushuaia",
    "Pacific/Pago_Pago",
    "Europe/Vienna",
    "Australia/Lord_Howe",
    "Antarctica/Macquarie",
    "Australia/Hobart",
    "Australia/Currie",
    "Australia/Melbourne",
    "Australia/Sydney",
    "Australia/Broken_Hill",
    "Australia/Brisbane",
    "Australia/Lindeman",
    "Australia/Adelaide",
    "Australia/Darwin",
    "Australia/Perth",
    "Australia/Eucla",
    "Asia/Baku",
    "America/Barbados",
    "Asia/Dhaka",
    "Europe/Brussels",
    "Europe/Sofia",
    "Atlantic/Bermuda",
    "Asia/Brunei",
    "America/La_Paz",
    "America/Noronha",
    "America/Belem",
    "America/Fortaleza",
    "America/Recife",
    "America/Araguaina",
    "America/Maceio",
    "America/Bahia",
    "America/Sao_Paulo",
    "America/Campo_Grande",
    "America/Cuiaba",
    "America/Santarem",
    "America/Porto_Velho",
    "America/Boa_Vista",
    "America/Manaus",
    "America/Eirunepe",
    "America/Rio_Branco",
    "America/Nassau",
    "Asia/Thimphu",
    "Europe/Minsk",
    "America/Belize",
    "America/St_Johns",
    "America/Halifax",
    "America/Glace_Bay",
    "America/Moncton",
    "America/Goose_Bay",
    "America/Blanc-Sablon",
    "America/Toronto",
    "America/Nipigon",
    "America/Thunder_Bay",
    "America/Iqaluit",
    "America/Pangnirtung",
    "America/Atikokan",
    "America/Winnipeg",
    "America/Rainy_River",
    "America/Resolute",
    "America/Rankin_Inlet",
    "America/Regina",
    "America/Swift_Current",
    "America/Edmonton",
    "America/Cambridge_Bay",
    "America/Yellowknife",
    "America/Inuvik",
    "America/Creston",
    "America/Dawson_Creek",
    "America/Fort_Nelson",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Dawson",
    "Indian/Cocos",
    "Europe/Zurich",
    "Africa/Abidjan",
    "Pacific/Rarotonga",
    "America/Santiago",
    "America/Punta_Arenas",
    "Pacific/Easter",
    "Asia/Shanghai",
    "Asia/Urumqi",
    "America/Bogota",
    "America/Costa_Rica",
    "America/Havana",
    "Atlantic/Cape_Verde",
    "America/Curacao",
    "Indian/Christmas",
    "Asia/Nicosia",
    "Asia/Famagusta",
    "Europe/Prague",
    "Europe/Berlin",
    "Europe/Copenhagen",
    "America/Santo_Domingo",
    "Africa/Algiers",
    "America/Guayaquil",
    "Pacific/Galapagos",
    "Europe/Tallinn",
    "Africa/Cairo",
    "Africa/El_Aaiun",
    "Europe/Madrid",
    "Africa/Ceuta",
    "Atlantic/Canary",
    "Europe/Helsinki",
    "Pacific/Fiji",
    "Atlantic/Stanley",
    "Pacific/Chuuk",
    "Pacific/Pohnpei",
    "Pacific/Kosrae",
    "Atlantic/Faroe",
    "Europe/Paris",
    "Europe/London",
    "Asia/Tbilisi",
    "America/Cayenne",
    "Africa/Accra",
    "Europe/Gibraltar",
    "America/Godthab",
    "America/Danmarkshavn",
    "America/Scoresbysund",
    "America/Thule",
    "Europe/Athens",
    "Atlantic/South_Georgia",
    "America/Guatemala",
    "Pacific/Guam",
    "Africa/Bissau",
    "America/Guyana",
    "Asia/Hong_Kong",
    "America/Tegucigalpa",
    "America/Port-au-Prince",
    "Europe/Budapest",
    "Asia/Jakarta",
    "Asia/Pontianak",
    "Asia/Makassar",
    "Asia/Jayapura",
    "Europe/Dublin",
    "Asia/Kolkata",
    "Asia/Calcutta",
    "Asia/Jerusalem",
    "Indian/Chagos",
    "Asia/Baghdad",
    "Asia/Tehran",
    "Atlantic/Reykjavik",
    "Europe/Rome",
    "America/Jamaica",
    "Asia/Amman",
    "Asia/Tokyo",
    "Africa/Nairobi",
    "Asia/Bishkek",
    "Pacific/Tarawa",
    "Pacific/Enderbury",
    "Pacific/Kiritimati",
    "Asia/Pyongyang",
    "Asia/Seoul",
    "Asia/Almaty",
    "Asia/Qyzylorda",
    "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
    "Asia/Aqtobe",
    "Asia/Aqtau",
    "Asia/Atyrau",
    "Asia/Oral",
    "Asia/Beirut",
    "Asia/Colombo",
    "Africa/Monrovia",
    "Europe/Vilnius",
    "Europe/Luxembourg",
    "Europe/Riga",
    "Africa/Tripoli",
    "Africa/Casablanca",
    "Europe/Monaco",
    "Europe/Chisinau",
    "Pacific/Majuro",
    "Pacific/Kwajalein",
    "Asia/Yangon",
    "Asia/Ulaanbaatar",
    "Asia/Hovd",
    "Asia/Choibalsan",
    "Asia/Macau",
    "America/Martinique",
    "Europe/Malta",
    "Indian/Mauritius",
    "Indian/Maldives",
    "America/Mexico_City",
    "America/Cancun",
    "America/Merida",
    "America/Monterrey",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Chihuahua",
    "America/Ojinaga",
    "America/Hermosillo",
    "America/Tijuana",
    "America/Bahia_Banderas",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Africa/Maputo",
    "Africa/Windhoek",
    "Pacific/Noumea",
    "Pacific/Norfolk",
    "Africa/Lagos",
    "America/Managua",
    "Europe/Amsterdam",
    "Europe/Oslo",
    "Asia/Kathmandu",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Auckland",
    "Pacific/Chatham",
    "America/Panama",
    "America/Lima",
    "Pacific/Tahiti",
    "Pacific/Marquesas",
    "Pacific/Gambier",
    "Pacific/Port_Moresby",
    "Pacific/Bougainville",
    "Asia/Manila",
    "Asia/Karachi",
    "Europe/Warsaw",
    "America/Miquelon",
    "Pacific/Pitcairn",
    "America/Puerto_Rico",
    "Asia/Gaza",
    "Asia/Hebron",
    "Europe/Lisbon",
    "Atlantic/Madeira",
    "Atlantic/Azores",
    "Pacific/Palau",
    "America/Asuncion",
    "Asia/Qatar",
    "Indian/Reunion",
    "Europe/Bucharest",
    "Europe/Belgrade",
    "Europe/Kaliningrad",
    "Europe/Moscow",
    "Europe/Simferopol",
    "Europe/Kirov",
    "Europe/Astrakhan",
    "Europe/Volgograd",
    "Europe/Saratov",
    "Europe/Ulyanovsk",
    "Europe/Samara",
    "Asia/Yekaterinburg",
    "Asia/Omsk",
    "Asia/Novosibirsk",
    "Asia/Barnaul",
    "Asia/Tomsk",
    "Asia/Novokuznetsk",
    "Asia/Krasnoyarsk",
    "Asia/Irkutsk",
    "Asia/Chita",
    "Asia/Yakutsk",
    "Asia/Khandyga",
    "Asia/Vladivostok",
    "Asia/Ust-Nera",
    "Asia/Magadan",
    "Asia/Sakhalin",
    "Asia/Srednekolymsk",
    "Asia/Kamchatka",
    "Asia/Anadyr",
    "Asia/Riyadh",
    "Pacific/Guadalcanal",
    "Indian/Mahe",
    "Africa/Khartoum",
    "Europe/Stockholm",
    "Asia/Singapore",
    "America/Paramaribo",
    "Africa/Juba",
    "Africa/Sao_Tome",
    "America/El_Salvador",
    "Asia/Damascus",
    "America/Grand_Turk",
    "Africa/Ndjamena",
    "Indian/Kerguelen",
    "Asia/Bangkok",
    "Asia/Dushanbe",
    "Pacific/Fakaofo",
    "Asia/Dili",
    "Asia/Ashgabat",
    "Africa/Tunis",
    "Pacific/Tongatapu",
    "Europe/Istanbul",
    "America/Port_of_Spain",
    "Pacific/Funafuti",
    "Asia/Taipei",
    "Europe/Kiev",
    "Europe/Uzhgorod",
    "Europe/Zaporozhye",
    "Pacific/Wake",
    "America/New_York",
    "America/Detroit",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Indiana/Indianapolis",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Vevay",
    "America/Chicago",
    "America/Indiana/Tell_City",
    "America/Indiana/Knox",
    "America/Menominee",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/North_Dakota/Beulah",
    "America/Denver",
    "America/Boise",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "America/Juneau",
    "America/Sitka",
    "America/Metlakatla",
    "America/Yakutat",
    "America/Nome",
    "America/Adak",
    "Pacific/Honolulu",
    "America/Montevideo",
    "Asia/Samarkand",
    "Asia/Tashkent",
    "America/Caracas",
    "Asia/Ho_Chi_Minh",
    "Pacific/Efate",
    "Pacific/Wallis",
    "Pacific/Apia",
    "Africa/Johannesburg",
    "Etc/Greenwich",
  ].concat(Object.values(TIME_ZONE_GMT_SEARCH_KEY))
);

export const TIME_ZONE_SEARCH_QUERY_INDEX = {
  ...{
    "America/Los_Angeles": "pdt, pt, pst, pacific time zone",
    "America/Chicago": "ct, cst, cdt, central time zone",
    "America/Denver": "mt, mst, mdt, mountain time zone",
    "America/New_York": "et, est, eastern time zone",
    "Pacific/Honolulu": "hdt, hst, hawaiian time zone",
  },
  ...getZoneNameIndexFromGMTOffset(),
};

export function mapMsToIana(msTimeZone) {
  const msToIanaMap = {
    "Dateline Standard Time": "Etc/GMT+12",
    "UTC-11": "Etc/GMT+11",
    "Aleutian Standard Time": "America/Adak",
    "Hawaiian Standard Time": "Pacific/Honolulu",
    "Marquesas Standard Time": "Pacific/Marquesas",
    "Alaskan Standard Time": "America/Anchorage",
    "UTC-09": "Etc/GMT+9",
    "Pacific Standard Time (Mexico)": "America/Tijuana",
    "UTC-08": "Etc/GMT+8",
    "Pacific Standard Time": "America/Los_Angeles",
    "US Mountain Standard Time": "America/Phoenix",
    "Mountain Standard Time (Mexico)": "America/Chihuahua",
    "Mountain Standard Time": "America/Denver",
    "Central America Standard Time": "America/Guatemala",
    "Central Standard Time": "America/Chicago",
    "Easter Island Standard Time": "Pacific/Easter",
    "Central Standard Time (Mexico)": "America/Mexico_City",
    "Canada Central Standard Time": "America/Regina",
    "SA Pacific Standard Time": "America/Bogota",
    "Eastern Standard Time (Mexico)": "America/Cancun",
    "Eastern Standard Time": "America/New_York",
    "Haiti Standard Time": "America/Port-au-Prince",
    "Cuba Standard Time": "America/Havana",
    "US Eastern Standard Time": "America/Indianapolis",
    "Turks and Caicos Standard Time": "America/Grand_Turk",
    "Paraguay Standard Time": "America/Asuncion",
    "Atlantic Standard Time": "America/Halifax",
    "Venezuela Standard Time": "America/Caracas",
    "Central Brazilian Standard Time": "America/Cuiaba",
    "SA Western Standard Time": "America/La_Paz",
    "Pacific SA Standard Time": "America/Santiago",
    "Newfoundland Standard Time": "America/St_Johns",
    "Tocantins Standard Time": "America/Araguaina",
    "E. South America Standard Time": "America/Sao_Paulo",
    "SA Eastern Standard Time": "America/Cayenne",
    "Argentina Standard Time": "America/Argentina/Buenos_Aires",
    "Greenland Standard Time": "America/Godthab",
    "Montevideo Standard Time": "America/Montevideo",
    "Magallanes Standard Time": "America/Punta_Arenas",
    "Saint Pierre Standard Time": "America/Miquelon",
    "Bahia Standard Time": "America/Bahia",
    "UTC-02": "Etc/GMT+2",
    "Azores Standard Time": "Atlantic/Azores",
    "Cape Verde Standard Time": "Atlantic/Cape_Verde",
    UTC: "Etc/GMT",
    "GMT Standard Time": "Europe/London",
    "Greenwich Standard Time": "Atlantic/Reykjavik",
    "Sao Tome Standard Time": "Africa/Sao_Tome",
    "Morocco Standard Time": "Africa/Casablanca",
    "W. Europe Standard Time": "Europe/Berlin",
    "Central Europe Standard Time": "Europe/Budapest",
    "Romance Standard Time": "Europe/Paris",
    "Central European Standard Time": "Europe/Warsaw",
    "W. Central Africa Standard Time": "Africa/Lagos",
    "Jordan Standard Time": "Asia/Amman",
    "GTB Standard Time": "Europe/Bucharest",
    "Middle East Standard Time": "Asia/Beirut",
    "Egypt Standard Time": "Africa/Cairo",
    "E. Europe Standard Time": "Europe/Chisinau",
    "Namibia Standard Time": "Africa/Windhoek",
    "Georgian Standard Time": "Asia/Tbilisi",
    "Iran Standard Time": "Asia/Tehran",
    "Arabian Standard Time": "Asia/Riyadh",
    "Azerbaijan Standard Time": "Asia/Baku",
    "Russia Time Zone 3": "Europe/Samara",
    "Mauritius Standard Time": "Indian/Mauritius",
    "Saratov Standard Time": "Europe/Saratov",
    "Volgograd Standard Time": "Europe/Volgograd",
    "Caucasus Standard Time": "Asia/Yerevan",
    "Afghanistan Standard Time": "Asia/Kabul",
    "West Asia Standard Time": "Asia/Tashkent",
    "Ekaterinburg Standard Time": "Asia/Yekaterinburg",
    "Pakistan Standard Time": "Asia/Karachi",
    "Qyzylorda Standard Time": "Asia/Qyzylorda",
    "India Standard Time": "Asia/Kolkata",
    "Sri Lanka Standard Time": "Asia/Colombo",
    "Nepal Standard Time": "Asia/Kathmandu",
    "Central Asia Standard Time": "Asia/Almaty",
    "Bangladesh Standard Time": "Asia/Dhaka",
    "Omsk Standard Time": "Asia/Omsk",
    "Myanmar Standard Time": "Asia/Yangon",
    "SE Asia Standard Time": "Asia/Bangkok",
    "Altai Standard Time": "Asia/Barnaul",
    "W. Mongolia Standard Time": "Asia/Hovd",
    "North Asia Standard Time": "Asia/Krasnoyarsk",
    "N. Central Asia Standard Time": "Asia/Novosibirsk",
    "Tomsk Standard Time": "Asia/Tomsk",
    "China Standard Time": "Asia/Shanghai",
    "North Asia East Standard Time": "Asia/Irkutsk",
    "Singapore Standard Time": "Asia/Singapore",
    "W. Australia Standard Time": "Australia/Perth",
    "Taipei Standard Time": "Asia/Taipei",
    "Ulaanbaatar Standard Time": "Asia/Ulaanbaatar",
    "Aus Central W. Standard Time": "Australia/Eucla",
    "Transbaikal Standard Time": "Asia/Chita",
    "Tokyo Standard Time": "Asia/Tokyo",
    "North Korea Standard Time": "Asia/Pyongyang",
    "Korea Standard Time": "Asia/Seoul",
    "Yakutsk Standard Time": "Asia/Yakutsk",
    "Cen. Australia Standard Time": "Australia/Adelaide",
    "AUS Central Standard Time": "Australia/Darwin",
    "E. Australia Standard Time": "Australia/Brisbane",
    "AUS Eastern Standard Time": "Australia/Sydney",
    "West Pacific Standard Time": "Pacific/Port_Moresby",
    "Tasmania Standard Time": "Australia/Hobart",
    "Vladivostok Standard Time": "Asia/Vladivostok",
    "Lord Howe Standard Time": "Australia/Lord_Howe",
    "Bougainville Standard Time": "Pacific/Bougainville",
    "Russia Time Zone 10": "Asia/Srednekolymsk",
    "Magadan Standard Time": "Asia/Magadan",
    "Norfolk Standard Time": "Pacific/Norfolk",
    "Sakhalin Standard Time": "Asia/Sakhalin",
    "Central Pacific Standard Time": "Pacific/Guadalcanal",
    "Russia Time Zone 11": "Asia/Kamchatka",
    "New Zealand Standard Time": "Pacific/Auckland",
    "UTC+12": "Etc/GMT-12",
    "Fiji Standard Time": "Pacific/Fiji",
    "Chatham Islands Standard Time": "Pacific/Chatham",
    "UTC+13": "Etc/GMT-13",
    "Tonga Standard Time": "Pacific/Tongatapu",
    "Samoa Standard Time": "Pacific/Apia",
    "Line Islands Standard Time": "Pacific/Kiritimati",
  };
  const mappedTimeZone = msToIanaMap[msTimeZone];

  if (mappedTimeZone && isValidTimeZone(mappedTimeZone)) {
    return mappedTimeZone;
  }
  return null;
}

export function getValidFloatingPointGMTTimeZone(offsetHours) {
  if (!isTypeOfNumber(offsetHours)) {
    return;
  }
  return FLOATING_POINT_GMT_TIMEZONES[offsetHours.toString()];
}
