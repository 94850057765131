import classNames from "classnames";
import React, { useState, useRef } from "react";
import GroupVoteSchedulingTable from "./groupVoteSchedulingTable";
import { Calendar } from "rbc-fork-react-big-calendar";
import StatelessCalendarToolBar from "./statelessCalendarToolBar";
import StatelessCalendarDayHeader from "./statelessCalendarDayHeader";
import _ from "underscore";
import { format, startOfHour, subHours } from "date-fns";
import {
  defaultSlotGetter,
  defaultCustomDayLayout,
  determineRBCLocalizer,
} from "../../lib/rbcFunctions";
import {
  SELECT_AVAILABILITY_COLOR,
} from "../../services/globalVariables";
import {
  determineSlotAttendeeIndex,
  createKeyFromSlot,
  createTemporaryEvent,
  isSameSlot,
  getInitialBookingLinkDay,
  isSlotInSelectSlots,
  parseEventsWithDefaultTimeZone,
} from "../../lib/availabilityFunctions";
import CustomEvent from "../customEvent";
import DisabledButton from "../disabledButton";
// import { EXAMPLE_BOOKING_LINK } from "./testFunctions";

const CALENDAR_COMPONENTS = {
  event: CustomEvent,
  toolbar: StatelessCalendarToolBar,
  week: {
    header: StatelessCalendarDayHeader,
  },
};

export default function GroupVotePreview({
  bookingLink,
  setName,
  onClickSaveAttendeeSlots,
  isMobile,
}) {
  // const bookingLink = EXAMPLE_BOOKING_LINK;
  // skipDarkMode is to remove dark mode styling on preview
  const [isTable, setIsTable] = useState(true);
  const [localizer] = useState(determineRBCLocalizer(0));

  const newAttendeeSlots = useRef([]);
  const [newUserName, setUserName] = useState("");
  const [currentDate, setCurrentDate] = useState(
    getInitialBookingLinkDay(bookingLink)
  );

  const onChangeUserName = (e) => {
    setName && setName(e.target.value);
    setUserName(e.target.value);
  };

  // useEffect(() => {
  //   // remove calendar view for mobile?
  //   if (isMobile && !isTable) {
  //     setIsTable(true);
  //   }
  // }, [isMobile])

  const hasNewAttendeeCheckedSlot = (slot) => {
    return newAttendeeSlots.current.some((s) => isSameSlot(s, slot));
  };

  const onClickEvent = (slot) => {
    if (hasNewAttendeeCheckedSlot(slot)) {
      const updatedSlots = newAttendeeSlots.current.filter(
        (s) => !isSameSlot(s, slot)
      );
      newAttendeeSlots.current = updatedSlots;
      setSelectedSlots(getSelectedSlotsForRbc());
    } else {
      const updatedSlots = newAttendeeSlots.current.concat({
        eventStart: slot.eventStart,
        eventEnd: slot.eventEnd,
      });
      newAttendeeSlots.current = updatedSlots;
      setSelectedSlots(getSelectedSlotsForRbc());
    }
  };

  const getSelectedSlotsForRbc = () => {
    const filteredSlotList = parseEventsWithDefaultTimeZone(bookingLink);
    const availabilityEvents = filteredSlotList.map((s, index) => {
      const { eventStart, eventEnd } = s;
      const key = createKeyFromSlot(s);
      const slotCount =
        slotCountIndex && slotCountIndex[key] ? slotCountIndex[key].length : 0;

      return {
        ...createTemporaryEvent({
          startTime: eventStart,
          endTime: eventEnd,
          index,
          hideCancel: true,
        }),
        displayGroupVoteInfo: true,
        slotCount,
        onClickEvent,
        isChecked: hasNewAttendeeCheckedSlot(s),
        hideSlotCount: bookingLink.anonymous, // do not need to hide on create event
      };
    });

    return availabilityEvents;
  };

  const [slotCountIndex] = useState(determineSlotAttendeeIndex(bookingLink));
  const [selectedSlots, setSelectedSlots] = useState(getSelectedSlotsForRbc());

  const renderUnderline = (isSelected) => {
    return (
      <div
        className={classNames(
          "w-10 h-1 mt-1.5 rounded",
          isSelected ? "bg-blue-500" : "bg-transparent"
        )}
      ></div>
    );
  };

  const renderScheduler = () => {
    if (isTable) {
      return (
        <div
          className={classNames(
            "overflow-y-auto",
            isMobile ? "" : "group-vote-table-scroll-wrapper"
          )}
        >
          <div className="flex w-full justify-center">
            <GroupVoteSchedulingTable
              bookingLink={bookingLink}
              newAttendeeSlots={newAttendeeSlots.current}
              setNewAttendeeSlot={onClickEvent}
              newUserName={newUserName}
              onChangeUserName={onChangeUserName}
            />
          </div>
        </div>
      );
    } else {
      return renderCalendar();
    }
  };

  const onClickSave = () => {
    onClickSaveAttendeeSlots &&
      onClickSaveAttendeeSlots(newAttendeeSlots.current);
  };

  const renderSaveButton = () => {
    if (newAttendeeSlots.current.length === 0) {
      return <DisabledButton label="Save" />;
    }

    return (
      <div
        className={classNames(
          "custom-button-box width-90px margin-right-10 text-white",
          "blue-button"
        )}
        onClick={onClickSave}
      >
        {"Save"}
      </div>
    );
  };

  const renderCalendar = () => {
    const timeRangeFormat = (slots) => {
      let { start, end } = slots;

      let formatStyle = "h:mm a";

      return `${format(start, formatStyle)} - ${format(end, formatStyle)}`;
    };

    let CALENDAR_TIME_FORMATS = {
      timeGutterFormat: "ha",
      dayFormat: (date) => {
        return date.toISOString();
      },
      // dayRangeHeaderFormat: 'D MMMM dddd',
      eventTimeRangeFormat: _.noop,
      selectRangeFormat: timeRangeFormat,
    };

    const eventStyleGetter = (event) => {
      const isEventInSelectedSlots = isSlotInSelectSlots(
        event,
        newAttendeeSlots.current
      );
      const determineBorderColor = () => {
        if (isEventInSelectedSlots) {
          return "#4A90E2";
        }

        return "white";
      };

      const availabilityStyle = {
        backgroundColor: SELECT_AVAILABILITY_COLOR,
        color: "white",
        borderWidth: isEventInSelectedSlots ? "3px" : "1px",
        borderColor: determineBorderColor(),
        borderStyle: "solid",
      };
      return { style: availabilityStyle };
    };

    const determineInitialScroll = () => {
      return subHours(startOfHour(currentDate), 1);
    };

    const onNavigate = (date) => {
      setCurrentDate(date);
    };

    return (
      <>
        <div className="w-full py-2.5 group-vote-selector-background-color flex justify-center mb-2.5 rounded-md">
          <input
            value={newUserName}
            onChange={onChangeUserName}
            placeholder={"Enter your name"}
            autoComplete="new-password"
            className={classNames("group-vote-name-input", isMobile ? "w-10/12" : "w-80", "font-size-12")}
          />
        </div>
        <div className={isMobile ? "h-full" : "group-scheduling-calendar-wrapper"}>
          <Calendar
            date={currentDate}
            onNavigate={onNavigate}
            className={classNames(
              "remove-rbc-day-header-click",
              "group-vote-calendar",
              "remove-dark-mode-rbc-style"
            )}
            events={selectedSlots}
            defaultView={"week"}
            localizer={localizer}
            components={CALENDAR_COMPONENTS}
            step={15}
            timeslots={4}
            toolbar={true}
            slotPropGetter={defaultSlotGetter}
            startAccessor="eventStart"
            endAccessor="eventEnd"
            allDayAccessor="displayAsAllDay"
            showMultiDayTimes={true}
            dayLayoutAlgorithm={defaultCustomDayLayout}
            formats={CALENDAR_TIME_FORMATS}
            eventPropGetter={eventStyleGetter}
            scrollToTime={determineInitialScroll()}
          />
        </div>
      </>
    );
  };

  const renderTableCalendarPicker = () => {
    return (
      <div className="flex items-center justify-center w-full mb-2.5 relative">
        <div
          className={classNames(
            "cursor-pointer mr-5 flex flex-col items-center select-none",
            isTable ? "group-vote-selected-blue" : "hoverable-secondary-text-color",
            "font-size-14"
          )}
          onClick={() => setIsTable(true)}
        >
          Table {renderUnderline(isTable)}
        </div>
        <div
          className={classNames(
            "cursor-pointer flex flex-col items-center select-none",
            !isTable ? "group-vote-selected-blue" : "hoverable-secondary-text-color",
            "font-size-14"
          )}
          onClick={() => setIsTable(false)}
        >
          Calendar {renderUnderline(!isTable)}
        </div>
      </div>
    );
  };
  return (
    <div className={classNames("preview-table-calendar-container")}>
      {renderTableCalendarPicker()}
      {renderScheduler()}
      <div
        className={classNames(
          "w-full sticky bottom-0 flex justify-end",
          isMobile ? "h-20 items-start pt-2" : "h-16 items-center",
          "bg-white"
        )}
      >
        {renderSaveButton()}
      </div>
    </div>
  );
}
